<template>
<main v-if="isLoading">
  <div style="background-color: white !important; min-height: 100vh; display: flex; justify-content: center; align-items: center;">
    <i class="fas fa-spinner fa-spin" style="font-size: 40px;"></i>
  </div>
</main>
<main v-else>
      <div>
        <div style="background-color: white !important;">
          <div class="row no-gutters">
              <div class="col-lg-6 col-md-0 col-sm-0 bg-auth-left" :style="{backgroundImage: `url(${loginpict})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '100vh'}">
                  <!-- <img v-bind:src="loginpict" alt="login" class="login-card-img" v-if="loginpict != ''"> -->
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12" style="min-height: 100vh; display: flex; align-items: center; justify-content: center;">
                <div class="content-auth-right">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 pb-0 pt-0 mt-0 mb-0" style="display: flex; justify-content: center;">
                      <div style="height: 120px;">
                        <img v-bind:src="parentCompanyInfo.logo_login_register" alt="" style="max-width: 100%;max-height: 100%;" v-if="parentCompanyInfo.logo_login_register != ''">
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12" style="display: flex; justify-content: center; margin-bottom: 24px; margin-top: 24px;">
                      <p style="font-size: 24px; font-weight: bold; text-align: center;">Log in to your account</p>
                    </div>
                  </div>
                  <el-card class="box-card card-auth-mobile" style="padding-top: 20px;">
                    <ValidationObserver  v-slot="{ handleSubmit }">
                    <form ref="frmlogin" @submit.prevent="handleSubmit(checkLogin)">
                        <ValidationProvider
                          name="email"
                          rules="required|email"
                          v-slot="{ passed, failed, errors }"
                        >
                        <base-input
                          id="loginusr"
                          required
                          v-model="email"
                          type="email"
                          placeholder="Email"
                          addon-left-icon="tim-icons icon-email-85"
                          autocomplete=nofill
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                        
                      <ValidationProvider
                        name="password"
                        rules="required|min:6"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        required
                        v-model="password"
                        placeholder="Password"
                        addon-left-icon="tim-icons icon-lock-circle"
                        type="password"
                        autocomplete="chrome-off"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                      <small v-if="loginusrnotexist"><span style="color:#ec250d">* Sorry, username or password invalid</span></small>
                      </ValidationProvider>
                      <div class="col-lg-12 col-md-12 pl-0 pt-2 pb-2">
                        <base-checkbox :disabled="agreeTermDisable" v-model="chckAgreetc" class="text-left" :class="{'has-danger': chckAgreetcStat}">
                          I have read and agree to the <a href="#termofuse" style="color:#919aa3;text-decoration:underline;font-weight:bold" :style="[chckAgreetcStat?{'color':'#ec250d'}:'']" v-on:click.stop.prevent="modals.termofuse = true">Terms of Use</a> and <a href="#privacypolicy" style="color:#919aa3;text-decoration:underline;font-weight:bold" :style="[chckAgreetcStat?{'color':'#ec250d'}:'']" v-on:click.stop.prevent="modals.privacypolicy = true">Privacy Policy</a>.
                        </base-checkbox>
                      </div>
                        <!--<base-button native-type="submit" type="danger" class="mb-3" size="lg" block>
                          Login
                        </base-button>-->
                        <div class="col-lg-12 col-md-12 pl-0 pt-4 pb-4">
                        <grecaptcha
                          v-if="sitekey && !modals.twoFactorAuth"
                          ref="recaptcha"
                          :sitekey="sitekey"
                          :callback="callback"
                          v-on:recaptchaID="getrecaptchaid1"
                        ></grecaptcha>
                         <small v-if="errorcaptcha"><span style="color:#ec250d">* Please prove you are human by click the recaptcha</span></small>
                        </div>
                        <button :disabled="modals.twoFactorAuth ? '' : isSubmitting" type="submit" class="btn mb-3 btn-block btn-lg">{{ modals.twoFactorAuth ? 'Login' : btnLgnText }}</button>
                    </form>
                    </ValidationObserver>
                    <!--<div class="or-container">
                        <div class="line-separator"></div>
                        <div class="or-label">or</div>
                        <div class="line-separator"></div>
                    </div>
                    <div> 
                      <a class="btn mb-3 btn-block  btn-lg" href="#" v-on:click="login_google"><img src="https://img.icons8.com/color/16/000000/google-logo.png"> Sign in with Google</a> 
                    </div>-->
                      <p class="text-center"><a href="#!" v-on:click.stop.prevent="modals.forgotpass = true" class="login-text-link" style="font-weight:bold;text-decoration:underline">Reset Password</a></p>
                      <p class="login-text-link text-center" v-if="agencyregister == false && showRegisterUrl == true">Don't have an account yet? <router-link :to="{ name: 'Register' }" style="font-weight:bold;text-decoration:underline">Create an Account</router-link></p>
                      <p class="login-text-link text-center"  v-if="agencyregister == true && showRegisterUrl == true">Do you want to be agency? <router-link :to="{ name: 'Agency Register' }" style="font-weight:bold;text-decoration:underline">Create an Agency</router-link></p>
                      <!--<nav class="pull-right ">
                        <a href="#!" class="login-text-link">Terms of use.</a>&nbsp;
                        <a href="#!" class="login-text-link">Privacy policy</a>
                      </nav>-->
                    </el-card>
                </div>
                
              </div>
          </div>
        </div>
        
      </div>

      <modal :show.sync="modals.twoFactorAuth" class="modal__2fa">
        <div style=" display: flex; justify-content: center; margin-bottom: 16px;">
          <img src="/img/2fa.jpg" alt="two factor auth image" style="max-width: 250px;" />
        </div>
        <div>
          <p style="font-size: 24px; font-weight: bold; text-align: center;">Two-Factor Authentication</p>
        </div>

        <!-- Email -->
        <EmailVerif v-if="twoFactorAuthType == 'email'" :isTwoFactorAuth="modals.twoFactorAuth" :onLogin="getUserData" :emailTwoFactorAuth="emailTwoFactorAuth" :isLoadingVerifyTwoFactorAuth="isLoadingVerifyTwoFactorAuth" />
        <!-- Email -->

        <!-- Google -->
        <GoogleAuthenticator v-if="twoFactorAuthType == 'google'" :isTwoFactorAuth="modals.twoFactorAuth" :onLogin="getUserData" :emailTwoFactorAuth="emailTwoFactorAuth" :isLoadingVerifyTwoFactorAuth="isLoadingVerifyTwoFactorAuth" />
        <!-- Google -->

      </modal>

      <modal id="modalForgot" :show.sync="modals.forgotpass" headerClasses="justify-content-center" modalContentClasses="modal-forgotpassword">
              <h4 slot="header" class="title title-up">Reset Password</h4>
              <div class="text-center">
                <img src="/img/forgotpass.png" width="50%" />
              </div>
              <div>
                <ValidationObserver  v-slot="{ handleSubmit }">
                    <form ref="frmForgot" @submit.prevent="handleSubmit(resetpassword)">
                        <ValidationProvider
                          name="email"
                          rules="required|email"
                          v-slot="{ passed, failed, errors }"
                        >
                        <base-input
                          id="emailforgot"
                          required
                          v-model="emailforgot"
                          :errorstatus= erroremail
                          v-on:focus="emailfocus"
                          type="email"
                          placeholder="Enter your email to reset your password"
                          autocomplete="off"
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                        <small v-if="errorusrnotexist"><span style="color:#ec250d">* Sorry, Email or Username not exist</span></small>
                      </ValidationProvider>
                      
                      <div>
                          <grecaptcha
                                v-if="sitekey"
                                ref="recaptchaforgot"
                                :sitekey="sitekey"
                                :callback="callback"
                                v-on:recaptchaID="getrecaptchaid2"
                              ></grecaptcha>
                              <small v-if="errorcaptchaforgot"><span style="color:#ec250d">* Please prove you are human by click the recaptcha</span></small>
                      </div>
                      <div class="container text-center pt-4">
                      <button :disabled="isSubmittingForgot"  type="submit" class="btn">{{ btnForgotText }}</button>
                      </div>
                    </form>
                </ValidationObserver>
                
              </div>
              <template slot="footer">
                
              </template>
      </modal>

            <!-- Term of Use Modal -->
            <modal :show.sync="modals.termofuse" headerClasses="justify-content-center" id="modaltermofuse">
              <div>
                <iframe src="/term-of-use" width="100%"  height="400vh"/>
              </div>
              <template slot="footer">
                <div class="container text-center pb-4">
                  <base-button  @click="modals.termofuse = false">Yes, I Understand</base-button>
                </div>
                <div class="container text-center pb-4">
                 <a href="/term-of-use/" target="_blank">Click here for Terms of use page</a>  
                </div>
              </template>
            </modal>
            <!-- Term of Use Modal -->

            <!-- Privacy Policy Modal -->
            <modal :show.sync="modals.privacypolicy" headerClasses="justify-content-center" id="modalprivacypolicy">
              <div>
                <iframe src="/privacy-policy" width="100%"  height="400vh"/>
              </div>
              <template slot="footer">
                <div class="container text-center pb-4">
                  <base-button  @click="modals.privacypolicy = false">Yes, I Understand</base-button>
                </div>
                <div class="container text-center pb-4">
                 <a href="/privacy-policy/" target="_blank">Click here for privacy policy page</a>  
                </div>
              </template>
            </modal>
            <!-- Term and Condition Modal -->
</main>
</template>
<script>

import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import grecaptcha from 'src/components/ReCaptcha.vue';
import swal from 'sweetalert2';
import { Modal, BaseButton } from '/src/components';
import { Card, Notification } from 'element-ui'
import { EmailVerif, GoogleAuthenticator } from '/src/components/Pages/TwoFactorAuthentication'

extend("email", email);
extend("min", min);

extend("required", required);

export default {
  components: { 
    grecaptcha,
    Modal,
    BaseButton,
    [Card.name]: Card,
    EmailVerif,
    GoogleAuthenticator,
   },
  data() {
    return {
      agencyregister:false,
      agreeTermDisable:false,
      chckAgreetcStat:false,
      chckAgreetc:false,
      layoutready: false,
      email: "",
      emailforgot: "",
      password: "",
      isSubmitting: false,
      isSubmittingForgot: false,
      loginpict: '',
      btnLgnText: 'Login',
      btnForgotText: 'Reset Password',
      clientId: '102371650871-f4vah1kuqe3bnh7epfdda51lv6m5hh5b.apps.googleusercontent.com',
      //clientId: '713037616374-al6okusv079frerqicpvq266dfvo33bq.apps.googleusercontent.com',
      sitekey: "",
      gtoken:null,
      errorcaptcha: false,
      errorcaptchaforgot: false,
      erroremail:false,
      errorusrnotexist:false,
      loginusrnotexist:false,
      recaptchaid1: "",
      recaptchaid2: "",
      modals: {
          forgotpass: false,
          termofuse: false,
          privacypolicy: false,
          twoFactorAuth: false,
      },
      parentCompanyInfo: {
        ownedcompanyid: "",
        domain: "",
        subdomain: "",
        logo_login_register: "",
        externalorgid: "",
      },
      tmpUserData : {
        leadlocalname: '',
        leadlocalurl: '',
        leadlocatorname: '',
        leadlocatorurl: '',
      },
      isLoading: false,
      showRegisterUrl: false,
      twoFactorAuthType: '',
      revalidateRecaptcha: false,
      emailTwoFactorAuth: '',
      isLoadingVerifyTwoFactorAuth: false,
    };
  },
  methods: {
    getrecaptchaid1(recaptchaid) {
      this.recaptchaid1 = recaptchaid;
    },
    getrecaptchaid2(recaptchaid) {
      this.recaptchaid2 = recaptchaid;
    },
    emailfocus() {
      this.erroremail = false;
    },
    resetpassword() {
      this.btnForgotText = 'Sending New Password...';
      this.isSubmittingForgot = true;
      
      this.$store.dispatch('forgetPass', {
        username: this.emailforgot,
        gtoken: this.gtoken,
        ownedcompanyid: this.parentCompanyInfo.ownedcompanyid,
        companyrootid: this.$global.idsys,
      })
      .then(response => {
        if(response == 'success') {
          this.modals.forgotpass = false;
          this.popresetpasswordsuccess();
          document.getElementById('loginusr').focus();
          document.getElementById('loginusr').select();
        }
           
      },error => {
          this.btnForgotText = 'Reset Password';
          this.isSubmittingForgot = false;
          this.errorcaptchaforgot = false;
          this.errorusrnotexist = false;
          window.grecaptcha.reset(this.recaptchaid2);
            Object.keys(error).forEach(key => {
              let errval = error[key][0]; // value of the current key
              //console.log(errval);
              if(errval == 'Recaptcha Invalid') {
                this.errorcaptchaforgot = true
              }else if(errval == 'User Not Exist') {
                this.erroremail = true;
                this.errorusrnotexist = true;
                document.getElementById('emailforgot').focus();
                document.getElementById('emailforgot').select();
              }

            })
      })

    },
    popresetpasswordsuccess() {
      swal.fire({
          title: 'New Password has been sent!',
          text: 'please check your email for your new password',
          timer: 2000,
          showConfirmButton: false,
          icon: 'success'
        });
        this.btnForgotText = 'Reset Password';
        this.isSubmittingForgot = false;
        this.errorcaptchaforgot = false;
        this.errorusrnotexist = false;
        this.emailforgot = "";
        window.grecaptcha.reset(this.recaptchaid2);
            Object.keys(error).forEach(key => {
              let errval = error[key][0]; // value of the current key
              //console.log(errval);
              if(errval == 'Recaptcha Invalid') {
                this.errorcaptchaforgot = true
              }else if(errval == 'User Not Exist') {
                this.erroremail = true;
                this.errorusrnotexist = true;
                document.getElementById('emailforgot').focus();
                document.getElementById('emailforgot').select();
              }

            })
    },
    callback(token) {
      this.gtoken = token
    },
    login_google() {
      //this.btnLgnText = 'Processing...';
      //this.isSubmitting = true;
      window.removeEventListener('message', this.startTokenSocial);
      window.addEventListener('message', this.startTokenSocial);

      var addparam = "";
      if (this.parentCompanyInfo.ownedcompanyid != '') {
        addparam = "/" + this.parentCompanyInfo.ownedcompanyid
      }
      var left = (screen.width/2)-(1024/2);
	    var top = (screen.height/2)-(800/2);
      var fbwindow = window.open(process.env.VUE_APP_DATASERVER_URL + '/auth/google/login' + addparam,'googlelogin',"menubar=no,toolbar=no,status=no,width=640,height=800,toolbar=no,location=no,modal=1,left="+left+",top="+top);
    },
    login() {
      if (!this.chckAgreetc) {
        this.chckAgreetcStat = true;
        return false;
      }else{
        this.chckAgreetcStat = false;
      }

      this.btnLgnText = 'Processing...';
      this.isSubmitting = true;
      this.$store.dispatch('retrieveToken', {
        username: this.email,
        password: this.password,
        gtoken: this.gtoken,
        domainName: window.location.hostname,
        ownedcompanyid: this.parentCompanyInfo.ownedcompanyid,
        companyrootid: this.$global.idsys,
      })
        .then(response => {
          if (typeof(response.data.result) != 'undefined') {
            if (response.data.result == 'redirect') {
              var confirmResult = confirm("Would you like us to redirect you to your agency's login URL?");
              if (confirmResult) {
                window.document.location = 'https://' + response.data.subdomain + '/';
              }
            }

            if(response.data.two_factor_auth){
              this.modals.twoFactorAuth = true
              this.twoFactorAuthType = response.data.two_factor_auth_type
              this.isSubmitting = false
              this.emailTwoFactorAuth = response.data.email
            } else {
              this.getUserData();
            }
          }else{
            this.getUserData();
          }
          
          
      },error => {
        this.btnLgnText = 'Login';
        this.isSubmitting = false;
        this.errorcaptcha = false
        this.loginusrnotexist = false;
        this.$refs.recaptcha.reset();
        if (error == 'Recaptcha Invalid') {
          this.errorcaptcha = true
        }else{
            //this.loginpict = 'img/EMMLogin.png';
            //this.email = '';
            //this.password = '';
            this.loginusrnotexist = true;
              document.getElementById('loginusr').focus();
              document.getElementById('loginusr').select();
            /*setTimeout(() => {
               this.loginpict = 'img/EMMLogin.png';
            }, 2000);
            */
        }
      })
    },
    getUserData() {
        this.isLoadingVerifyTwoFactorAuth = true
        this.$store.dispatch('retrieveUserData',{})
        .then(response => {
          console.log(response);
          
          if(response == 'success') {
            window.removeEventListener('message', this.startTokenSocial);
            
            const userData = this.$store.getters.userData;
            
             /** SET TO STORAGE FOR SIDEMENU */
                // userData.leadlocalname = this.$global.globalModulNameLink.local.name;
                // userData.leadlocalurl = this.$global.globalModulNameLink.local.url;

                // userData.leadlocatorname = this.$global.globalModulNameLink.locator.name;
                // userData.leadlocatorurl = this.$global.globalModulNameLink.locator.url;
                
                this.$global.globalModulNameLink.local.name = userData.leadlocalname;
                this.$global.globalModulNameLink.local.url = userData.leadlocalurl;

                this.$global.globalModulNameLink.locator.name = userData.leadlocatorname;
                this.$global.globalModulNameLink.locator.url = userData.leadlocatorurl;

                this.$global.globalModulNameLink.enhance.name = userData.leadenhancename;
                this.$global.globalModulNameLink.enhance.url = userData.leadenhanceurl;

                this.$global.globalModulNameLink.b2b.name = userData.leadb2bname;
                this.$global.globalModulNameLink.b2b.url = userData.leadb2burl;

                userData.idsys = this.$global.idsys;

                const updatedData = {
                  leadlocalname: this.$global.globalModulNameLink.local.name,
                  leadlocalurl: this.$global.globalModulNameLink.local.url,
                  leadlocatorname: this.$global.globalModulNameLink.locator.name,
                  leadlocatorurl: this.$global.globalModulNameLink.locator.url,
                  idsys: this.$global.idsys
                }

                this.$store.dispatch('updateUserData', updatedData);
             /** SET TO STORAGE FOR SIDEMENU */
             
             const usetupcompleted = this.$store.getters.userData.profile_setup_completed
             if (usetupcompleted == "F") {
              window.document.location = "/user/profile-setup";
             }else{
               //const userData = this.$store.getters.userData;
               if (userData.menuLeadspeek == true && userData.user_type == 'client' && userData.leadspeek_type == 'locator') {
                  window.document.location = "/" + this.$global.globalModulNameLink.locator.url + '/dashboard';
               }else if (userData.menuLeadspeek == true && userData.user_type == 'client' && userData.leadspeek_type == 'local') {
                  window.document.location = "/" + this.$global.globalModulNameLink.local.url + '/dashboard';
               }else if (((userData.user_type == 'sales' && userData.status_acc != 'completed') || (userData.user_type == 'sales' && userData.status_acc == 'completed' && userData.isAccExecutive == 'F') ) && userData.systemuser) {
                  window.document.location = "/configuration/sales-connect-account";
               }else if ((userData.user_type == 'user' || userData.user_type == 'userdownline' || userData.user_type == 'sales') && userData.systemuser) {
                  window.document.location = "/configuration/agency-list";
               }else if ((userData.user_type == 'user' || userData.user_type == 'userdownline') && (userData.charges_enabled == false || userData.payouts_enabled == false)) {
                  window.document.location = "/configuration/general-setting";
               }else if (userData.user_type == 'user') {
                  window.document.location = "/" + this.$global.globalModulNameLink.local.url + '/dashboard';
               }else{
                 //window.document.location = "/user/profile-setup";
                 window.document.location = "/" + this.$global.globalModulNameLink.local.url + '/dashboard';
               }
             }
             this.isLoadingVerifyTwoFactorAuth = false
          }else{
            this.btnLgnText = 'Login';
            this.isSubmitting = false;
            swal.fire({
              icon: 'error',
              title: 'Sorry!',
              text: 'There is currently an update processing on the platform. Please wait five minutes and try again.',
            })
            this.isLoadingVerifyTwoFactorAuth = false
          }

        })

    },

    startTokenSocial(e) {
      if (e.origin == process.env.VUE_APP_DATASERVER_URL) {
        if (e.data == 'Login Invalid') {
          this.btnLgnText = 'Login';
          this.isSubmitting = false;
          this.errorcaptcha = false
          this.loginusrnotexist = false;
          this.$refs.recaptcha.reset();

          this.loginusrnotexist = true;
          document.getElementById('loginusr').focus();
          document.getElementById('loginusr').select();

        }else{
          this.putTokenSocial(e.data);
        }
      }
    },

    putTokenSocial(acctkn) {
      this.$store.dispatch('putTokenSocial', {
        acctoken: acctkn,
      })
        .then(response => {
          if(response != 'loginfailed') {
            this.getUserData();
          }
      })
    },

    adjustrecaptcha() {
      setTimeout(function() {
        var width = $('.g-recaptcha').parent().width();
        if (width < 302) {
          var scale = width / 285;
          $('.g-recaptcha').css('transform', 'scale(' + scale + ')');
          $('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
          $('.g-recaptcha').css('transform-origin', '0 0');
          $('.g-recaptcha').css('-webkit-transform-origin', '0 0');
        } 
      }, 600);
    },
    async checkdomainsubdomain() {
      this.isLoading = true
      var currurl = window.location.hostname
      const mainDomain = currurl.replace(/(.*?)\.(?=.*\.)/, '');

      await this.$store.dispatch('getDomainorSubInfo', {
          domainorsub: window.location.hostname,
        }).then(response => {
            //console.log(response);
            $('link[rel="icon"]').attr('href', response.params.logo_login_register);
            $('link[rel="apple-touch-icon"]').attr('href', response.params.logo_login_register);
            $('meta[name="apple-mobile-web-app-title"]').attr('content',response.params.companyname);

            if (response.params.ownedcompanyid == "") {
              document.location = 'https://' + response.params.subdomain;
            }
            this.parentCompanyInfo.ownedcompanyid = response.params.ownedcompanyid;
            this.parentCompanyInfo.domain = response.params.domain;
            this.parentCompanyInfosubdomain = response.params.subdomain;
            this.parentCompanyInfo.logo_login_register = response.params.logo_login_register;
            this.parentCompanyInfo.externalorgid  = response.params.externalorgid;
            this.loginpict = response.params.login_image;
            // this.$global.globalBoxBgColor = response.params.box_bgcolor;
            // this.$global.globalTemplateBgColor = response.params.template_bgcolor;
            this.layoutready = true;
            this.$global.idsys = response.params.idsys;
            this.$global.recapkey = response.params.recapkey;
            this.sitekey = response.params.recapkey;
            document.title = response.params.companyname + " Login";
            this.isLoading = false

            /** FOR AGENCY REGISTER LINK */
             this.agencyregister = false;
            if (response.params.idsys == response.params.ownedcompanyid) {
              this.agencyregister = true;
            }
            /** FOR AGENCY REGISTER LINK */

            /** CHECK IF REGISTER LINK WILL SHOW OR NOT */
            if (this.agencyregister) {
              if (response.urlredirect.agencyregisterurl != "#" || response.urlredirect.agencyregisterurl == "") {
                this.showRegisterUrl = true;
              }
            }else{
              if ((response.params.disabledclientregister == 'T' && (response.params.clientredirecturl != '' || response.params.clientredirecturl !== null) && response.params.clientredirecturl != '#') || (response.params.disabledclientregister == 'F' && (response.params.clientredirecturl == '' || response.params.clientredirecturl === null))) {
                this.showRegisterUrl = true;
              }
            }
            /** CHECK IF REGISTER LINK WILL SHOW OR NOT */

            /** FOR CUSTOM SIDE MENU NAME */
              if (response.sidemenu != '') {
                this.$global.globalModulNameLink.local.name = response.sidemenu.local.name;
                this.$global.globalModulNameLink.local.url = response.sidemenu.local.url;

                this.$global.globalModulNameLink.locator.name = response.sidemenu.locator.name;
                this.$global.globalModulNameLink.locator.url = response.sidemenu.locator.url;

                this.$global.globalModulNameLink.enhance.name = response.sidemenu.enhance.name;
                this.$global.globalModulNameLink.enhance.url = response.sidemenu.enhance.url;
              }
            /** FOR CUSTOM SIDE MENU NAME */

            /** SET TO STORAGE FOR SIDEMENU */

              this.tmpUserData.leadlocalname = this.$global.globalModulNameLink.local.name;
              this.tmpUserData.leadlocalurl = this.$global.globalModulNameLink.local.url;

              this.tmpUserData.leadlocatorname = this.$global.globalModulNameLink.locator.name;
              this.tmpUserData.leadlocatorurl = this.$global.globalModulNameLink.locator.url;

              const updatedData = {
                  leadlocalname: this.$global.globalModulNameLink.local.name,
                  leadlocalurl: this.$global.globalModulNameLink.local.url,
                  leadlocatorname: this.$global.globalModulNameLink.locator.name,
                  leadlocatorurl: this.$global.globalModulNameLink.locator.url
              }

              this.$store.dispatch('updateUserData', updatedData);
            /** SET TO STORAGE FOR SIDEMENU */

        },error => {
            this.isLoading = false
            //this.parentCompanyInfo.logo_login_register = "/img/EMMLogo.png";
            //alert('Your domain or subdomain not register yet');
            document.location = 'https://' + mainDomain;
        })
    },
    checkLogin(){
      if (!this.chckAgreetc) {
        this.chckAgreetcStat = true;
        return false;
      }else{
        this.chckAgreetcStat = false;
      }

      if (!this.gtoken) {
        Notification.error({
          title: 'Error',
          message: 'Please check recaptcha'
        });
        return false;
      }

      if (this.revalidateRecaptcha && !this.modals.twoFactorAuth) {
        Notification.error({
          title: 'Error',
          message: 'Please revalidate recaptcha'
        });

        return false;
      }

      this.login();

      // if (this.twoFactorAuthType) {
      //   this.modals.twoFactorAuth = true;
      // } else {
      //   this.login();
      // }

    },
  },
  watch: {
    'modals.twoFactorAuth': function(newVal, oldVal) {
      if (newVal) {
        return;
      } else {
        this.gtoken = ''
        this.isSubmitting = false
        this.btnLgnText = 'Login';
      }
    },
    gtoken: function(newVal, oldVal){
      if(newVal){
        this.revalidateRecaptcha = false
        setTimeout(() => {
          this.revalidateRecaptcha = true
        }, 60000)
      } else {
        this.revalidateRecaptcha = false
      }
    },
  },
  mounted() {
     document.title = "Login";
      this.checkdomainsubdomain();
      this.$store.dispatch('destroyToken')
      this.adjustrecaptcha();
        /** DISABLED RIGHT CLICK AND CONSOLE WARNING IF NOT GLOBAL VIEW */
   
        window.addEventListener('contextmenu', function (e) {
          e.preventDefault();
        });
        
        function consoleWarning() {
            console.log("%cWarning!", "color: red; font-size: 24px;");
            console.log("%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature or 'hack' someone's account, it is a scam and will give them access to your account.", "font-size: 16px;");
        }

        // Check if console is open
        var consoleOpened = false;
        setInterval(function() {
            if (!consoleOpened) {
                consoleOpened = true;
                consoleWarning();
            }
        }, 1000);
       /** DISABLED RIGHT CLICK AND CONSOLE WARNING IF NOT GLOBAL VIEW */

  },
};
</script>
<style>
.full-page > .content {
  padding-top: 0px !important;
}

.modal-forgotpassword {
  max-width: 520px;
  margin: 0 auto;
}
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

#modalForgot .modal-body .form-group input{
  color:#222a42;
}

.g-recaptcha div {
  margin: 0 auto;
}

.login-page .btn-danger,.login-page .btn-danger:hover {
  background-color: #942434 !important;
  background-image: linear-gradient(to bottom left, #942434, #ec250d, #fd5d93) !important;
}

.login-page .signtext {
  margin:0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
}

.login-page .content {
  height: 100vh;
  position: relative;
}

.login-page .box-login {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(-50%,-50%);
  left: 50%;
}
.login-page .box-login input:-webkit-autofill,
.login-page .box-login input:-webkit-autofill:hover, 
.login-page .box-login input:-webkit-autofill:focus
{
  -webkit-box-shadow: none !important;
  -webkit-text-fill-color: black !important;
  background-color: transparent !important;
  transition: background-color 50s ease-in-out 0s  !important;
}
.login-page .login-card {
  margin-bottom: 0px !important;
}

.login-page .logo-card {
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page .error {
  display: none;
}

.bg-auth-left {
  display: block;
}

.content-auth-right {
  padding: 16px !important;
  max-width: 500px;
}

.modal__2fa .modal-dialog .modal-content {
  max-width: 520px;
  margin: auto;
}

@media (max-width: 991px) {
  .bg-auth-left {
    display: none;
  }
}

@media (max-width: 767px) {
  .content-auth-right {
    padding: 16px !important;
  }
}

@media (max-width: 374px) {
    .card-auth-mobile {
      max-width: 315px;
    }
}
</style>
